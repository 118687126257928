import React from 'react'
import { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import AppStateProvider from '../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/heroImage.svg'
import { Container } from 'components/PageContainer'
import { makeStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'
import TeamMember from 'src/components/TeamMember'
import './styles/meettheteam.scss'
import photoJoin from '../assets/images/photoJoin.svg'
function RootIndex() {

  const [practitioners, setPractitioners] = useState(null);

  useEffect(() => {
    let index = 0;
    fetch('https://api.swiftdoc.com/api/practitioner/public?size=100')
      .then((response) => response.json())
      .then(data => data.map(d => ({
        memberName: d.name,
        memberTitle: d.title,
        memberParagraphs: d.description,
        memberBubblePhoto: d.image,
        index: index++,
      })))
      .then(ps => setPractitioners(ps))
  }, [])

  return (
    <AppStateProvider>
      <Layout title="Meet the Team | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="Friendly, professional and approachable medical professionals"
            subTitle="Get Instant Access to Online Doctors "
            pageName="MEET THE TEAM"
          />
          <div className="bgWhite">
              <p className="onlyParagraph">
                SwiftDoc offers online doctor services through video
                consultations for a range of medical services. We cover a wide
                range of health issues via video link, and, in addition to GP
                services we offer specialist services such as mental health and
                counselling, diet and nutrition.
              </p>
          </div>
          <Container>
            <div className="teamSection">
              <h1 style={{ textAlign: 'center' }}>
                Introducing the SwiftDoc team of online doctors
              </h1>
            </div>

            {practitioners && practitioners.map((p, i) => 
              <div key={i} className="memberSection">
                <TeamMember teamMember={p} imageSide={p.index % 2 === 0 ? "right" : "left"} />
              </div>  
              )}
          </Container>
          <div className="bookAnAppointment">
            <Container>
              <div className="bookText">
                <div className="bookTitle">
                  <h1>
                    All of our medical professionals are ready to work with you
                    to address your health concerns.
                  </h1>
                </div>
                <div className="bookSubTitle">
                  <p>Simply book an appointment for a video consult.</p>
                </div>
              </div>
              <a href="https://app.swiftdoc.com">
                <Button color="default" id="baaButton" className="baaButton">
                  BOOK AN APPOINTMENT
                </Button>
              </a>
            </Container>
          </div>
          <div className="workWithSD">
            <div
              className="imageContainer"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '-1',
                height: '100%',
                width: '100%',
              }}
            >
              <img
                src={photoJoin}
                alt=""
                style={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>

            <Container>
              <div className="workText">
                <div className="workTitle">
                  <h1>
                    Are you a medical or allied health professional? 
                  </h1>
                </div>
                <div className="workSubTitle">
                  <p>Click below to start the application process</p>
                </div>
              </div>
              <a href="https://app.swiftdoc.com/careers">
                <Button color="default" id="workButton" className="workButton">
                  APPLY
                </Button>
              </a>
            </Container>
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
const useStyles2 = makeStyles((theme) => ({}))
